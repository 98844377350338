<template>
  <v-container fluid>
    <!-- <Breadcrumbs /> -->
    <!-- Web Hooks Creds Input -->
    <v-card class="rounded secondarygrad ma-4">
      <v-row class="ma-0">
        <v-col>
          <v-card
            color="transparent"
            elevation="0"
            style="padding: 10px; text-align: center !important"
          >
            <v-card-text style="margin-bottom: 25px">
              <div align="center" style="margin-bottom: 10px; margin-top:20%;">
                <h1 style="margin-bottom: 40px">Godial + Webhook</h1>
              </div>
              <div style="padding: 5px 15px; text-align: center">
                <h5
                  style="
                        line-height: 17px;
                        font-weight: 500;
                        font-size: 14px;
                      "
                >
                  Connect GoDial with Your CRM. GoDial will call the webHook you
                  specify to send data for you to consume.
                </h5>

                <div style=" text-align: left">
                  <p
                    style="margin-top:20px;font-size: 18px;font-weight: 600;margin-bottom:10px;"
                  >
                    Uses
                  </p>
                  1. Connect your CRM <br />2. Update your Software <br />3.
                  Trigger any workflow
                </div>

                <div>
                  <v-expansion-panels focusable style="padding-top:20px">
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >Request Sample</v-expansion-panel-header
                      >
                      <v-expansion-panel-content style=" text-align: left;">
                        <v-expansion-panel-content style="padding-bottom:5px">
                          <!-- <v-layout row mt-4>
                                  <v-flex
                                    xs4
                                    s3
                                    m2
                                    style="display:flex; justify-content:center;"
                                  >
                                    <v-btn
                                      style="padding:20px;margin-top:4px;"
                                      color="green"
                                    >
                                      TEST
                                    </v-btn>
                                  </v-flex>
                                  <v-flex
                                    xs8
                                    s9
                                    m10
                                    style="display:flex; justify-content:center;"
                                  >
                                    <v-text-field
                                      :value="webHook.targetUrl"
                                      placeholder="Target Url"
                                      readonly
                                      solo
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout> -->
                          <v-layout>
                            <v-flex>
                              <v-card class="ma-3">
                                <v-tabs
                                  v-model="contentTab"
                                  background-color="primarygrad"
                                  dark
                                  grow
                                  show-arrows
                                >
                                  <v-tab v-for="item in tabsHeader" :key="item">
                                    {{ item }}
                                  </v-tab>
                                </v-tabs>

                                <v-tabs-items v-model="contentTab">
                                  <v-tab-item>
                                    <v-card flat>
                                      <v-card-text>
                                        <v-card
                                          style="background-color:#cfd1c9;"
                                        >
                                          <pre style="overflow-x: scroll">
   
    "headers":{ 
                "x-forwarded-for":"XX.XX.190.226",
                "x-forwarded-proto": "https", 
                "x-forwarded-port":"443", 
                "host": "XXXXXXXX", 
                "x-amzn-trace-id":"Root=1-60b05a6f-09c622002cb13572301fb11d",
                "content-length": "860",
                "accept":"application/json,text/plain, */*",
                "content-type": "application/json;charset=utf-8",
                "authentication": "basic asdasdasdasdasdasd",
                "user-agent": "axios/0.19.2" 
              }
                                </pre
                                          >
                                        </v-card>
                                      </v-card-text>
                                    </v-card>
                                  </v-tab-item>

                                  <v-tab-item>
                                    <v-card flat>
                                      <v-card-text>
                                        <v-card
                                          style="background-color:#cfd1c9;"
                                        >
                                          <pre style="overflow-x: scroll">
 
   
            {
                    "id":"5eaa8946effbd9313c5e6d2b_2633231974",
                    "name":"Jhonny Gusman", 
                    "phone": "2633231974",
                    "secondPhone": "", 
                    "email":"scordovap@merriam-webster.com",
                    "companyName":"Test Company", 
                    "note": "Test note",
                    "remarks":"", 
                    "extra": "Extra Vals", 
                    "called":false, 
                    "createdOn": "2021-05-20T02:30:41.233Z",
                    "calledOn": "2021-05-20T04:16:27.000Z",
                    "modifiedOn": "2021-05-26T05:38:52.533Z",
                    "callbackDone": true, 
                    "dispo": "",
                    "callback":false, 
                    "lock": false, 
                    "dnc": false,
                    "ftsContact": false, 
                    "companyId":"5e9d3b85effbd9313c5e4f26", 
                    "listId": "5eaa8946effbd9313c5e6d2b", 
                    "accountsId": "5eaa892feffbd9313c5e6d28", 
                    "assignee": null,
                    "smsSent": false, 
                    "emailSent": false, 
                    "linkSent":false, 
                    "appSent" : false,
                    "customFields": { 
                      "Test": "", 
                      "TEST2":
                      "test custom", 
                      "CUSTOM": "", 
                      "DATEFIRLD":
                      "2021-05-11" 
                      }, 
                      "callStart": 1621484187939,
                    "defaultDialer": "Phone Dialer", 
                    "duration": 57,
                    "timeSpentOnCall": 2850, 
                    "totalDuration": 57,
                    "eventType":
                    "contactUpdate"//ThisischangedtologUpdateforcalls
            }
                                </pre
                                          >
                                        </v-card>
                                      </v-card-text>
                                    </v-card>
                                  </v-tab-item>

                                  <v-tab-item>
                                    <v-card flat>
                                      <v-card-text>
                                        <v-card
                                          style="background-color:#cfd1c9;"
                                        >
                                          <pre style="overflow-x: scroll">
   
    "bodyRaw":"{
          \"id\":\"5eaa8946effbd9313c5e6d2b_2633231974\",
          \"name\":\"Jhonny Gusman\",
          \"phone\":\"2633231974\",
          \"secondPhone\":\"\",
          \"email\":\"scordovap@merriam-webster.com\",
          \"companyName\":\"TestCompany\",
          \"note\":\"Test note\",
          \"remarks\":\"\",
          \"extra\":\"Extra Vals\",
          \"called\":false,
          \"createdOn\":\"2021-05-20T02:30:41.233Z\",
          \"calledOn\":\"2021-05-20T04:16:27.000Z\",
          \"modifiedOn\":\"2021-05-26T05:38:52.533Z\",
          \"callbackDone\":true,
          \"dispo\":\"\",
          \"callback\":false,
          \"lock\":false,
          \"dnc\":false,
          \"ftsContact\":false,
          \"companyId\":\"5e9d3b85effbd9313c5e4f26\",
          \"listId\":\"5eaa8946effbd9313c5e6d2b\",
          \"accountsId\":\"5eaa892feffbd9313c5e6d28\",
          \"assignee\":null,
          \"smsSent\":false,
          \"emailSent\":false,
          \"linkSent\":false,
          \"appSent\":false,
          \"customFields\":{
            \"Test\":\"\",
          \"TEST2\":\"testcustom\",
          \"CUSTOM\":\"\",
          \"DATEFIRLD\":\"2021-05-11\"
          },
          \"callStart\":1621484187939,
          \"defaultDialer\":\"PhoneDialer\",
          \"duration\":57,
          \"timeSpentOnCall\":2850,
          \"totalDuration\":57,
          \"eventType\":\"contactUpdate\"
      }"
                                </pre
                                          >
                                        </v-card>
                                      </v-card-text>
                                    </v-card>
                                  </v-tab-item>
                                </v-tabs-items>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-expansion-panel-content>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
            </v-card-text>
            <v-footer color="transparent" absolute>
              <v-flex style="text-align: center !important">
                <p style="font-size: 12px; opacity: 0.9; margin-bottom: 0px">
                  Feel free to reach out to us at support@godial.cc if you are
                  interested in these features
                </p>
              </v-flex>
            </v-footer>
          </v-card>
        </v-col>
        <v-col>
          <v-card flat class="transparent mt-3">
            <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
              <v-layout row wrap>
                <v-flex d-flex justify-center style="padding-top: 22px;"
                  >Enable
                </v-flex>
                <v-flex d-flex justify-center>
                  <v-switch
                    v-model="webHook.enabled"
                    color="primary"
                  ></v-switch>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-card>
          <v-card
            color="transparent"
            elevation="0"
            style="padding: 15px 43px; text-align: center;"
            @keyup.enter="doLogin"
          >
            <v-card-title class="justify-center"
              >WebHook Credentials</v-card-title
            >

            <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
              <v-text-field
                v-bind:disabled="!webHook.enabled"
                v-model="webHook.targetUrl"
                label="Target URL"
                type="url"
                outlined
                dense
                prepend-inner-icon="perm_identity"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
              <v-layout row wrap>
                <v-flex d-flex justify-center style="padding-top: 22px;"
                  >Custom Headers</v-flex
                >
                <v-flex d-flex justify-center>
                  <v-switch
                    v-model="webHook.headers"
                    color="primary"
                  ></v-switch>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex v-if="webHook.headers" xs12 sm10 offset-sm1 md10 offset-md1>
              <v-text-field
                v-bind:disabled="!webHook.enabled"
                v-model="webHook.headerKey"
                label="Header Key"
                type="text"
                outlined
                dense
                prepend-inner-icon="stay_primary_portrait"
              ></v-text-field>
              <v-text-field
                v-bind:disabled="!webHook.enabled"
                v-model="webHook.headerValue"
                label="Header Value"
                type="text"
                outlined
                dense
                prepend-inner-icon="stay_primary_portrait"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
              <div style=" text-align: left">
                <p style="margin-top:20px;font-size: 18px;">Events</p>
              </div>

              <v-container>
                <v-switch
                  v-model="webHook.afterCallEvent"
                  label="After Call"
                  value="true"
                  color="green"
                ></v-switch>

                <v-switch
                  v-model="webHook.contactUpdateEvent"
                  label="Contact Update"
                  value="true"
                  color="green"
                ></v-switch>
              </v-container>
            </v-flex>

            <v-card-text>
              <v-flex
                xs12
                sm10
                offset-sm1
                md10
                offset-md1
                style="margin-bottom: 10px"
              >
                <v-btn
                  color="primarygrad white--text"
                  block
                  @click="setWebhookCompany"
                  >Save</v-btn
                >
              </v-flex>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primary white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      webHook: {
        targetUrl: "",
        headers: false,
        headerKey: "",
        headerValue: "",
        afterCallEvent: false,
        contactUpdateEvent: false,
        enabled: false,
      },
      tabsHeader: ["Headers", "Body", "Body Raw"],
      contentTab: null,
      defaultDialer: { name: "Phone Dialer", value: "Phone Dialer" },
      loader: false,
    };
  },

  computed: {
    ...mapState(["ENDPOINT", "companyDetails"]),
    shadowCss() {
      if (this.webHook.enabled) return "blurui";
      else return "";
    },
  },
  watch: {
    companyDetails() {
      this.webHook = this.companyDetails.webHook;
    },
  },
  created() {
    if (this.companyDetails.webHook) {
      this.webHook = this.companyDetails.webHook;
    }
  },

  methods: {
    async setWebhookCompany() {
      function validURL(url) {
        var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
        if (!re.test(url)) {
          return false;
        }
        return true;
      }

      if (this.webHook.targetUrl == "") {
        return this.$swal({
          type: "warning",
          text: "Target URL Can't be Blank",
        });
      } else if (!validURL(this.webHook.targetUrl)) {
        return this.$swal({
          type: "warning",
          text: "Target URL is Invalid",
        });
      }

      if (this.webHook.headerKey == "" && this.webHook.headers === true) {
        return this.$swal({
          type: "warning",
          text: "Header Key Can't be Blank",
        });
      }

      if (this.webHook.headerValue == "" && this.webHook.headers === true) {
        return this.$swal({
          type: "warning",
          text: "Header Value Can't be Blank",
        });
      }

      this.loader = true;
      try {
        // Add Webhook Details In Company Data
        var company = await this.$http.patch(
          `${this.ENDPOINT}/companies/${this.$store.state.user.companyId}`,
          {
            webHook: {
              enabled: this.webHook.enabled,
              headers: this.webHook.headers,
              headerKey: this.webHook.headerKey,
              headerValue: this.webHook.headerValue,
              targetUrl: this.webHook.targetUrl,
              afterCallEvent: this.webHook.afterCallEvent,
              contactUpdateEvent: this.webHook.contactUpdateEvent,
            },
          }
        );

        // Success Message
        if (company.status == 200) {
          // Set Company Object for Store dispatch
          var companyObj = { companyId: company.body.id };
          this.$store.dispatch("getCompanyDetails", companyObj);
          this.loader = false;

          return this.$swal({
            type: "success",
            text: "Webhook Updated",
          });
        } else {
          return this.$swal({
            type: "warning",
            text: "Something Went Wrong",
          });
        }
      } catch (err) {
        console.log(err);
        this.loader = false;
        return this.$swal({
          type: "warning",
          text: err,
        });
      }
    },
  },
};
</script>

<style></style>
